import React, { forwardRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';

const Terms = forwardRef((props: any, ref: any) => {

  const { ref:reference, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

    return (
      <div ref={ref}>
      <div ref={reference}>
        <Fade bottom duration={1500}  when={inView} >
        <div className="flex flex-col gap-4 bg-gray-900 font-Lato px-4 lg:px-10 text-[1rem] rounded-[64px] py-10" >
          <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold mb-2 text-center'>Regulamin usługi</h2>
           <div><h3 className="font-bold">Regulamin korzystania z usługi ADAPTIVO</h3>
            <p>Niniejszy Regulamin określa zasady korzystania z usługi Adaptivo za pośrednictwem strony internetowej dostępnej pod adresem www.adaptivo.io należącej do: ETICOD sp. z o.o., z siedzibą w Katowicach (kod 40-309) przy ul. Grzegorzka 21, wpisanej przez Sąd Rejonowy  Katowice-Wschód w Katowicach, Wydział VIII Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000321577, NIP 627 266 37 23, REGON: 241087158, zwana dalej Usługodawcą.
</p></div>
            <div><h3 className="font-bold">Definicje.</h3>
            <p>Pojęcia użyte w Regulaminie oznaczają:<br />
Katalog – katalog zawierający informacje dotyczące skanowanego przez użytkownika asortymentu produktów oferowanych przez Usługodawcę lub powiązane podmioty, dostępny dla Użytkownika Zarejestrowanego po potwierdzeniu przez niego pełnoletniości w sposób opisany w Regulaminie;<br />
Konto – indywidualne konto prowadzone w systemie Usługodawcy i dostępne poprzez Platformę, umożliwiające korzystanie z Usługi; <br />
Regulamin – niniejszy regulamin;<br />
Regulamin strony internetowej ADAPTIVO – regulamin dostępny pod adresem: https://adaptivo.io/regulamin<br />
Usługa – zbiór funkcjonalności udostępnionych za pośrednictwem Platformy, umożliwiający Użytkownikowi Zarejestrowanemu dostęp do Katalogu po zalogowaniu się do Konta;<br />
Platforma - platforma internetowa dostępna pod adresem https://www.adaptivo.io<br />
Uczestnik -  osoba fizyczna, która spełnia określone w Regulaminie warunki uczestnictwa. <br />
Użytkownik -  osoba fizyczna korzystająca z Platformy;<br />
Użytkownik Zarejestrowany - pełnoletni Użytkownik, który dokonał rejestracji na Platformie i utworzył Konto uprawniające do korzystania z Usługi.
            </p>
</div>
<div>
    <h3 className="font-bold">Postanowienia ogólne</h3>
<p>Dostęp do Usługi jest realizowany za pośrednictwem dedykowanej podstrony (dostępnej pod adresem: https://www.adaptivo.io udostępnionej przez Usługodawcę w ramach Platformy.
Dostęp do Usługi przeznaczony jest wyłącznie dla osób pełnoletnich, które dokonały rejestracji i utworzyły Konto. Usługa nie jest dostępna dla Użytkowników lub Uczestników, którzy nie ukończyli 18 roku życia.
Usługa umożliwia zapoznanie się z wybranym asortymentem w regularnych lub promocyjnych cenach, a także w ramach akcji promocyjnych 
Dostępny w ramach Usługi Katalog jest informacją handlową i nie stanowi oferty w rozumieniu art. 66 §1 kodeksu cywilnego.
Do Katalogu znajdują zastosowanie wszystkie zasady dotyczące sposobu prezentacji produktów opisane w Regulaminie strony internetowej adaptivo.io  oraz własności materiałów służących do prezentacji produktów w ramach Katalogu.
Z tytułu korzystania z Usługi Usługodawca nie pobiera od Użytkowników żadnych opłat.
Warunkiem technicznym utworzenia Konta i korzystania z Usługi jest posiadanie przez Użytkownika aktywnej skrzynki poczty elektronicznej (e-mail). Pełny dostęp do funkcjonalności Usługi możliwy jest za pośrednictwem Platformy. Do uzyskania dostępu do Usługi za pośrednictwem Platformy niezbędne jest połączenie urządzenia Użytkownika z siecią Internet. W celu korzystania z Platformy niezbędne jest posiadanie urządzenia końcowego o rozdzielczości ekranu co najmniej 1368x768 z dostępem do sieci Internet wyposażonego w aktualną wersję przeglądarki internetowej Google Chrome, Mozilla Firefox, Apple Safari lub Microsoft Edge. Zaleca się korzystanie z wersji przeglądarki zgodnej z cyklem aktualizacji stosowanym przez jej producenta.</p>
        </div>
<div><h3 className="font-bold">Rejestracja i korzystanie z Usługi</h3>
<p>W celu rozpoczęcia z korzystania z Usługi należy dokonać rejestracji oraz potwierdzić swoją pełnoletność za pośrednictwem formularza dostępnego na Platformie Adaptivo (podając i potwierdzając datę urodzenia), a następnie zalogować się do Konta poprzez Platformę.
Rejestracja za pośrednictwem Platformy wymaga wypełnienia interaktywnego formularza, w szczególności podania swoich prawdziwych i poprawnych danych, stosując się do komunikatów wyświetlanych na Platformie. Usługodawca może udostępnić również inne możliwości dokonywania rejestracji za pośrednictwem Platformy, np. rejestrację poprzez zalogowanie się do swojego konta w serwisie społecznościowym (np. Google) lub w innej aplikacji.
Do rejestracji za pośrednictwem Platformy niezbędne jest podanie imienia, adresu e-mail oraz daty urodzenia, która potwierdza pełnoletniość Użytkownika. W przypadku rejestracji poprzez alternatywne metody wskazane w pkt 2 powyżej (o ile są udostępnione przez (Usługodawcę), Użytkownik może zostać poproszony o podanie dodatkowych informacji zgodnie z komunikatami pojawiającymi się w formularzu rejestracyjnym (np. nazwy użytkownika oraz adresu e-mail przypisanego do konta w serwisie społecznościowym lub aplikacji, daty urodzenia, która potwierdza pełnoletniość Użytkownika).
Osoba dokonująca rejestracji za pośrednictwem Platformy może dobrowolnie podać w trakcie rejestracji bądź na późniejszym etapie korzystania z Konta, dane dotyczące płci oraz numeru telefonu, a także wyrazić zgodę na komunikację marketingową oraz na udostępnianie danych partnerom Usługodawcy w celu ich marketingu własnego. Podanie danych dodatkowych i wyrażenie zgód, o których mowa w zdaniu poprzedzającym, jest w pełni dobrowolne i nie jest warunkiem korzystania z Usługi. Bezpośrednio po wysłaniu formularza rejestracyjnego Usługodawca tworzy dla zgłaszającej się osoby indywidualne Konto oraz generuje przypisany do tego Konta unikalny ID. Z tą chwilą rejestracja zostaje zakończona, a Użytkownik Zarejestrowany uzyskuje dostęp do Usługi. Z chwilą ukończenia rejestracji, Użytkownik Zarejestrowany zawiera z Usługodawcą na czas nieoznaczony umowę o świadczenie usługi dostępu do Konta zgodnie z Regulaminem. 
Zakończenie procesu rejestracyjnego oraz utworzenie Konta zostają niezwłocznie potwierdzone przez Usługodawcę poprzez wysłanie wiadomości elektronicznej na adres e-mail wskazany w formularzu rejestracyjnym. 
Użytkownik Zarejestrowany zobowiązany jest do zachowania w tajemnicy swojego identyfikatora użytkownika (ID), jak również innych danych niezbędnych do zalogowania się, i nieujawniania ich osobom trzecim.
Użytkownik Zarejestrowany ponosi pełną odpowiedzialność prawną za wszelkie szkody wyrządzone Usługodawcy wynikające z nieprzestrzegania postanowień Regulaminu, w tym, lecz nie wyłącznie, podania nieprawdziwych danych, w szczególności w odniesieniu do wieku Użytkownika</p>
</div>
<div><h3 className="font-bold">Reklamacje</h3>
<p>Użytkownikowi Zarejestrowanemu przysługuje prawo do wniesienia reklamacji w przypadku stwierdzenia niezgodności Usługi z postanowieniami Regulaminu, w zakresie dotyczącym obowiązków Usługodawcy.
Reklamacja związana z Usługą powinna zawierać dane identyfikacyjne osoby wnioskującej o reklamację (imię, nazwisko, adres), opis niezgodności stanowiącej podstawę reklamacji wraz z wyrażeniem żądania reklamacyjnego. Reklamację można złożyć:
drogą elektroniczną, kierując wiadomość na adres e-mail: biuro@eticod.pl
w formie pisemnej, kierując korespondencję na adres siedziby Usługodawcy
Odpowiedź na reklamację zostanie udzielona niezwłocznie, w każdym przypadku nie później niż w terminie 30 dni od dnia jej otrzymania, chyba że bezwzględnie obowiązujące przepisy prawa określają krótszy termin na udzielenie odpowiedzi na reklamację.
Przetwarzanie danych osobowych
Administratorem danych osobowych Użytkowników Zarejestrowanych jest Usługodawca, tj. ETICOD sp. z o.o., z siedzibą w Katowicach. Szczegółowe informacje dotyczące przetwarzania danych osobowych Użytkowników Zarejestrowanych, w tym informacje o prawach przysługujących Użytkownikom Zarejestrowanym w związku z korzystaniem z Usługi, są dostępne w Polityce prywatności, dostępnej pod adresem: https://adaptivo.io/polityka. 
Postanowienia końcowe
Postanowienia niniejszego Regulaminu nie wpływają w żaden sposób na dotychczasowe uprawnienia Uczestników Programu. Uczestnicy nie są zobowiązani do korzystania z Usługi, ani do podejmowania żadnych dodatkowych działań w związku z udostępnieniem Usługi przez Usługodawcę.
W zakresie nieuregulowanym niniejszym Regulaminem, w szczególności w odniesieniu do zasad rozwiązywania umowy o świadczenie Usługi oraz utrzymania Konta, a także praw Użytkowników będących konsumentami do odstąpienia od umowy o świadczenie usług, stosuje się przepisy Regulaminu usługi Adaptivo.
Językiem, w jakim zawierana jest umowa o świadczenie Usługi pomiędzy Usługodawcą a Użytkownikiem Zarejestrowanym, jest język polski.
W ramach korzystania z Usługi, zabronione jest dostarczanie przez Użytkowników treści bezprawnych, naruszających prawo lub dobre obyczaje.
Regulamin udostępniany jest w formie elektronicznej, umożliwiającej jego przechowywanie i odtwarzanie w zwykłym toku czynności, na stronie internetowej:
https://adaptivo.io
W kwestiach nieuregulowanych w Regulaminie znajdują zastosowanie przepisy prawa powszechnie obowiązującego w Rzeczypospolitej Polskiej, w tym Kodeks cywilny oraz ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.
Regulamin wchodzi w życie z dniem 20 października 2023 r.</p>

        </div>
        </div>
        </Fade>
      </div>
   
      </div>
    );
  });
  
  export default Terms;