import React, { forwardRef, useState } from 'react';
import { Button } from '../UI/Button';

import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';

import Stats1 from '../../static/stats1.svg';
import Stats2 from '../../static/stats2.svg';
import Stats3 from '../../static/stats3.svg';

import StatsModal from './StatsModal';

const Stats = forwardRef((props: any, ref: any) => {

  const { ref: reference, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }
   

    return (
      <div ref={ref}>
      <div ref={reference}>
      <Fade bottom duration={1500}  when={inView} >
      <div className="flex flex-col gap-4 lg:justify-center lg:items-center font-Lato px-4 py-8 xl:py-32 bg-gray-900 rounded-[2rem] relative lg:px-4 xl:px-48 fullHD:justify-center">
        <div className='flex flex-col gap-4 lg:justify-center lg:items-center w-fit'>
        <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold mb-2'>Monitoruj zachowanie swoich klientów!</h2>
        <div className='flex flex-col gap-8 xl:flex-row '>
            <div className='flex flex-col gap-4 xl:order-2 break-words 2xl:max-w-[587px]'>
                <p className='text-[1.125rem]'>Dane to luksus na który nie każdy moze sobie pozwolić. Dzięki Adaptivo lepiej poznasz swoich klientów i zyskasz przewagę nad konkurencją!</p>
                <p className='text-[1.125rem]'>System posiada wiele narzędzi. Jednym z nich jest moduł z badaniem Net Promoter Score (NPS). To potężne narzędzie, które pozwala firmom na zrozumienie, na ile są w stanie spełniać oczekiwania swoich klientów.</p>
                <p className='text-[1.125rem]'><b>W systemie Adaptivo</b> zapanujesz nad czytelnością statystyk i <b>lepiej zrozumiesz potrzeby klientów.</b></p>
                <Button text={"Sprawdź, co możesz zyskać!"} onClick={handleOpen}/>
            </div>
            <img src={Stats1} className='xl:order-1 xl:h-[375px] xl:w-[481px] w-full rounded-[32px]'/>
        </div>
        <div className='flex flex-col gap-4 xl:gap-8 xl:flex-row w-full'>
        <img src={Stats2} className='xl:h-[375px] xl:w-[566px] w-full rounded-[32px]'/>
        <img src={Stats3} className='xl:h-[375px] xl:w-max w-full rounded-[32px]'/>
        </div>

        </div>
        
      </div>
      </Fade>
      </div>
      <StatsModal open={open} handleClose={handleClose} contact={props.contact}/>
      </div>
    );
  });
  
  export default Stats;