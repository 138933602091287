import React, { forwardRef, useState } from 'react';
import Tile from './Tile';
import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';
import AboutModal from './AboutModal';

const About = forwardRef((props: any, ref: any) => {

  const { ref:reference, inView } = useInView({
    threshold: 0,
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  }


    return (
      <div ref={reference} className='xl:flex xl:justify-center'>
    <div ref={ref} className='fullHD:max-w-[80%] '>
      <Fade bottom duration={1500} when={inView}>
      <div className="flex flex-col gap-2 lg:justify-center  font-Lato px-4 lg:px-10 " >
      
        <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold'>Jak działa system?</h2>
        
        <div className='flex flex-col gap-8 xl:flex-row'>
            <Tile header={"Twój Produkt w Rękach Klienta"} 
            text={"Anna jest w sklepie i zastanawia się nad zakupem nowego produktu. Skanuje kod QR na opakowaniu i natychmiast otrzymuje informacje o pochodzeniu i składzie produktu. Dzięki temu ma pewność, że produkt, który wybiera, jest godny zaufania."}
            buttonText={"Sprawdź jak wygląda strona mobilna"}
            type={0}
            reference={props.contact}
            />
            <Tile header={"Kontrolujesz Ruchy Twojego Produktu"} 
            text={"Jako producent, otrzymujesz dostęp do mapy śledzenia w czasie rzeczywistym. Obserwujesz, gdzie i kiedy Twoje produkty są skanowane. Analizujesz dane i dostosowujesz swoje strategie marketingowe oraz dystrybucyjne do rzeczywistych danych."}
            buttonText={"Co zyskają moi klienci?"}
            type={1}
           
            handleOpen={handleOpen}
            />
            </div>
           
      </div>
      </Fade>
      </div>
      <AboutModal open={open} handleClose={handleClose} contact={props.contact}/>
      </div>
    );
  });
  
  export default About;