
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Backdrop } from '@mui/material';
import React, { forwardRef, Ref, RefObject, useEffect,FC,ReactNode } from 'react';
import { Button } from '../UI/Button';
import close from '../../static/close.svg';
import modalImage1 from '../../static/modalImage1.png';
import modalImage2 from '../../static/modalImage2.png';
import learnMore from '../../static/learnMore.png';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { motion } from "framer-motion"

const style = {
  position: 'absolute' as 'absolute',
  top: '10%',
  left: '0%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  height: "90%",
  bgcolor: '#010101',
  color: "#fff",
  border: '2px solid #000',
  boxShadow: 24,
  p: "0.5rem 2.5rem",
  borderRadius: "2rem 2rem 0  0",
  overflowY:'scroll',
  '@media (min-width: 1920px)': {
    width: '80%',
    left: '10%', 
  },
};

export default function AboutModal(props: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const redSquare =  <motion.svg width="75" height="75" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <linearGradient id="paint0_linear_3719_28310" x1="45.3907" y1="32.4206" x2="-11.6884" y2="33.6159" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FF237B" />
      <stop offset="1" stopColor="#EC40D0" />
    </linearGradient>
  </defs>
  <motion.rect
    width="75"
    height="75"
    initial={{ width: 0 }}
    animate={{ width: 75 }}
    transition={{ duration: 0.5, delay: 1 }}
    fill="url(#paint0_linear_3719_28310)" // Apply the linear gradient fill
  />
</motion.svg>

  const purpleSquare =  <motion.svg width="250" height="75" xmlns="http://www.w3.org/2000/svg">
  <defs>
  <linearGradient id="paint0_linear_3719_28392" x1="75.5868" y1="29.488" x2="-18.8663" y2="31.5628" gradientUnits="userSpaceOnUse">
        <stop stop-color="#667BFF"/>
        <stop offset="1" stop-color="#B74AE3"/>
    </linearGradient>
  </defs>
  <motion.rect
    width="75"
    height="75"
    initial={{ width: 0 }}
    animate={{ width: 250 }}
    transition={{ duration: 0.5, delay: 2 }}
    fill="url(#paint0_linear_3719_28392)" // Apply the linear gradient fill
  />
</motion.svg>

  const blueSquare = <motion.svg width="500" height="75" xmlns="http://www.w3.org/2000/svg">
  <defs>
  <linearGradient id="paint0_linear_3719_30515" x1="616.526" y1="34.6285" x2="-7.52804" y2="353.012" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00D5EF"/>
    <stop offset="1" stop-color="#2273FF"/>
    </linearGradient>
  </defs>
  <motion.rect
    width="75"
    height="75"
    initial={{ width: 0 }}
    animate={{ width: 500 }}
    transition={{ duration: 0.5, delay: 3 }}
    fill="url(#paint0_linear_3719_30515)" // Apply the linear gradient fill
  />
  </motion.svg>



  const circle = (number: number, delay: number) => {
    return(
    <motion.svg 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5, delay: delay }}
    xmlns="http://www.w3.org/2000/svg" width="82" height="75" viewBox="0 0 82 75" fill="none">
        <path d="M43.7443 74.9444C64.4378 74.9444 81.2148 58.1674 81.2148 37.4705C81.2148 16.777 64.4378 0 43.7443 0C25.585 0 10.4477 12.9216 7.01237 30.0679L0.253456 36.6947L6.88702 44.0533C10.0005 61.6027 25.3004 74.9444 43.7443 74.9444Z" fill="#655F74"/>
        <text x="55%" y="55%" text-anchor="middle" alignment-baseline="middle" fill="white" font-size="30px" fontWeight={700} >0{number}</text>
    </motion.svg>
  )}

  return (

      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
            sx: {
              
              backgroundColor: 'rgba(53, 53, 53, 0.9)',
              backdropFilter: "blur(10px)",
              
            },
          },
        }}
        
      >
        <Slide in={props.open} direction="up">
        <Box sx={style} >
        
          <div className='flex flex-col gap-0'>
            <div className='flex justify-end sticky top-0 right-0 -mr-2'>
            <img src={close} className='sticky top-5 -right-2 hover:cursor-pointer bg-black rounded-full -mr-8 p-2' onClick={()=>props.handleClose()}/>
            </div>
            <div className='flex flex-col gap-16'>
            
            <div className='flex flex-col gap-2'>
                <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold '>Twoja Rewolucja w Zakupach</h2>
                <p className='text-[1.125rem] lg:max-w-[42%]'>W dobie cyfryzacji, dostęp do informacji staje się nie tylko łatwiejszy, ale i bardziej wartościowy. Dzięki naszej innowacyjnej platformie Adaptivo, możesz teraz mieć pełną kontrolę nad tym, jak Twoi klienci postrzegają i reagują na Twoje produkty.</p>
                <Button text={"Umów się na rozmowę z konsultantem"} onClick={()=>props.handleClose()} reference={props.contact}/>
            </div>
            <div className='flex flex-col lg:gap-16 lg:flex-row'>
                <div className='flex flex-col gap-8 lg:max-w-[100%]'>
                    <div 
                        
                        
                    className='flex flex-col gap-8 lg:max-w-[60%] lg:flex-row'>
                        <div

                        className='flex gap-6'>
                        {redSquare}
                        {circle(1,1)}
                        </div>
                    
                        <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 1.5 }}
                        className='felx flex-col'>
                            <h4 className='text-[1.75rem]'>Personalizacja treści - Twoja zaleta konkurencyjna</h4>
                            <p className='text-[1.125rem] mb-4'>Dla tych, którzy chcą poznać więcej. Klienci po zalogowaniu mają dostęp do unikalnych treści: recenzji, linków do filmów demonstracyjnych czy praktycznych porad. Wszystko po to, by dokonali świadomego wyboru. Twój produkt już nie jest tylko kolejnym produktem na półce. Dzięki Adaptivo staje się indywidualnym doświadczeniem dla każdego użytkownika.</p>
                            <p className='text-[1.125rem]'>Zalogowani użytkownicy mają dostęp do historii swoich skanowań. Ta funkcja pozwala na szybki powrót do interesujących ich produktów, ułatwiając porównywanie różnych opcji i dokonywanie przemyślanych wyborów.</p>
                        </motion.div>
                    </div>
                    <div className='flex flex-col gap-8 lg:max-w-[80%] lg:flex-row'>
                        <div className='flex gap-6'>
                        {purpleSquare}
                        {circle(2,2)}
                        </div>
                    
                        <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 2.5 }}
                        className='felx flex-col'>
                            <h4 className='text-[1.75rem]'>Świadome zakupy dzięki Adaptivo</h4>
                            <p className='text-[1.125rem] mb-4'>Z nami użytkownik zyskuje pełen wgląd w produkt - od składników, przez pochodzenie, aż po opinie innych użytkowników. Taka wiedza pozwala dokonać świadomego wyboru, który jest w pełni zgodny z indywidualnymi wartościami i preferencjami każdego klienta.</p>
                            <p className='text-[1.125rem]'>Nie ma nic cenniejszego niż czas naszych klientów. Dzięki Adaptivo, dostęp do wszystkich niezbędnych informacji jest błyskawiczny, co pozwala zaoszczędzić cenny czas. To nie tylko wygoda, ale także pewność, że każdy zakup jest dokładnie taki, jakiego oczekiwano.</p>
                        </motion.div>
                    </div>
                    <div className='flex flex-col gap-8 lg:max-w-[100%] lg:flex-row'>
                        <div className='flex gap-6'>
                        {blueSquare}
                        {circle(3,3)}
                        </div>
                    
                        <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 3.5 }}
                         className='felx flex-col'>
                            <h4 className='text-[1.75rem]'>Adaptivo - przyszłość inteligentnych zakupów</h4>
                            <p className='text-[1.125rem] mb-4'>Z naszym narzędziem użytkownik ma pewność, że jego wybory zakupowe są dostosowane do jego indywidualnych potrzeb. Adaptivo to więcej niż platforma - to filozofia zakupów oparta na pewności i zaufaniu.</p>
                            <p className='text-[1.125rem]'>Każdy zakup staje się teraz przemyślany, szybki i komfortowy. Zapraszamy do wypróbowania Adaptivo i przekonania się samemu, jak technologia może zmienić sposób, w jaki robimy zakupy na co dzień.</p>
                        </motion.div>
                    </div>
                </div>
              </div>
              
              <div>
          <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 4 }} className="flex flex-col gap-8 justify-center items-center font-Lato px-4 lg:px-10 py-36 text-center " >
            <div className='flex flex-col gap-2'>
                <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold '>Chesz wiedzieć więcej?</h2>
                <p className='text-[1.125rem]'>Umów się na rozmowę z ekspertami i dowiedz się, jak na podstawie danych zdobyć znaczną przewagę konkurencyjną!</p>
            </div>

              <img src={learnMore} />
              <Button text={"Umów się na rozmowę"} onClick={()=>props.handleClose()} reference={props.contact}/>
            </motion.div>

          </div>
          </div>
        </div>
          
        </Box>
        </Slide>
      </Modal>
  );
}