import React from 'react';
import { Button } from '../UI/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import Arrow from '../../static/Arrow.svg'
import { isPossiblePhoneNumber} from 'react-phone-number-input'
import Success from '../../static/success.svg'


function Form(){

    const [view, setView] = useState(0);
    const phoneValidation = (errorMessage: string): Yup.StringSchema<string | undefined> =>
    Yup.string().test('testcheckPhone', errorMessage, function (value?: string) {
      const { path, createError } = this;
  
      if (value && !isPossiblePhoneNumber("+48" + value)) {
        return false;
      }
  
      return true;
    });
    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
        first_name: '',
        email: '',
        phone_number: '',
        industry: '',
        message: '',
        data_processing_consent: "false",
        },
        validationSchema: Yup.object({
            first_name: Yup.string()
            .required('Sprawdź, czy imię jest uzupełnione'),
            industry: Yup.string()
            .required('Uzupełnij informację o branży'),
            email: Yup.string()
            .email('Sprawdź swój e-mail')
            .required('Sprawdź swój e-mail'),
            phone_number: Yup.string()
            .required('Sprawdź, czy numer jest poprawny'),

        }),
        onSubmit: values => {
          
            fetch(process.env.REACT_APP_API_URL + `/mediator/send-contact-data-from-landing/`,{
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(values),
            })
            .then((res)=>{
                if(res.ok){setView(1)
            }
            else{
                return res.json()
            }
        }
        )
        .then((json)=>{
            json.errors.map((error: any) => {
                formik.setErrors({[error.attr]: error.detail})

            })
               
        })
            
        },
      });
      
      const handlePhoneChange = (e: any) => {
        const { value } = e.target;
      
        if (value === '' || /^[0-9]$/.test(e.target.value.slice(-1)) || e.target.value.slice(-1) === 'Backspace' || e.target.value.slice(-1) === 'Delete') {
         
          formik.setFieldValue("phone_number", value);
        } else {
        
        }
      }

    return(
        <div className="bg-gray-200 flex flex-col rounded-2xl p-8 gap-6 xl:w-[55%] text-black">
           {
            view === 0 ? <> <form className='flex flex-col gap-2'>
                <label>Twoje imię</label>  
                <input type="text" onChange={formik.handleChange("first_name")}
                            value={formik.values.first_name} placeholder="np. Michał"className={`border-gray-300 rounded-lg py-[10px] px-4 ${formik.touched.first_name && formik.errors.first_name  ? "errorInput" : ""} `}/>           
                 <span className='errorText'>{formik.errors.first_name}</span>
                 <label>Numer kontaktowy</label>
                <input type='text' onChange={(e)=>handlePhoneChange(e)}
                value={formik.values.phone_number} 
                className={`border-gray-300 rounded-lg py-[10px] px-4 ${formik.touched.phone_number && formik.errors.phone_number  ? "errorInput" : ""} `}  
                placeholder="np. 891 891 891"
                            />

                <span className='errorText'>{formik.errors.phone_number}</span> 
                <label>Adres e-mail</label>
                <input type="text" placeholder="np. michal.wikowski@twoja-firma.pl" onChange={formik.handleChange("email")}
                            value={formik.values.email} className={`border-gray-300 rounded-lg py-[10px] px-4 ${formik.touched.email && formik.errors.email  ? "errorInput" : ""} `}/>                
                <span className='errorText'>{formik.errors.email}</span> 
                <label>Wpisz branżę swojej firmy</label>
                <input type="text" placeholder="np. Kosmetyczna" onChange={formik.handleChange("industry")}
                            value={formik.values.industry} className={`border-gray-300 rounded-lg py-[10px] px-4 ${formik.touched.industry && formik.errors.industry  ? "errorInput" : ""} `}/>                
                <span className='errorText'>{formik.errors.industry}</span> 
                <label>Wpisz treść wiadomości (opcjonalne)</label>
                <textarea rows={5} placeholder="np. Proszę o kontakt w dni robocze po 15:00" onChange={formik.handleChange("message")}
                            value={formik.values.message} className={`border-gray-300 rounded-lg py-[10px] px-4 ${formik.touched.message && formik.errors.message  ? "errorInput" : ""} `}/>                
                <span className='errorText font-OpenSans font-bold'>{formik.errors.message}</span> 
               
                <span className='flex gap-2 items-start' onChange={formik.handleChange("data_processing_consent")}>
                    <input className='hover:cursor-pointer mt-1' type="checkbox" id="consent" name="consent"  value={formik.values.data_processing_consent} onChange={formik.handleChange("data_processing_consent")}/>
                    <label htmlFor="consent" className='extra-tiny-font hover:cursor-pointer' > Wyrażam zgodę na przetwarzanie moich danych osobowych przez firmę ETICOD sp. z o.o. zgodnie z regulaminem oraz polityką prywatności</label>
                </span>

                
            </form>
            <button className="items-center gap-3 justify-center bg-secondary-900 text-black rounded-full px-2 py-1 text-left w-full font-OpenSans text-[1rem] xl:hidden flex  " onClick={(e)=>formik.handleSubmit()}>
                <b>Wyślij wiadomość</b>
                <img src={Arrow} alt="arrow" className='bg-gray-900 rounded-full p-2'/>
            </button>
            <div className='flex justify-center'>
                
            <button className="hidden xl:flex items-center gap-3 justify-center bg-secondary-900 text-black rounded-full px-2 py-1 text-left w-full font-OpenSans text-[1rem] " onClick={(e)=>formik.handleSubmit()}>
                <b>Wyślij wiadomość i dowiedz się więcej</b>
                <img src={Arrow} alt="arrow" className='bg-gray-900 rounded-full p-2'/>
            </button>
            </div>
            </>
            :
            <>
            <div className='flex flex-col justify-center items-center'>
                <h3 className='text-[2rem] text-center'>Wiadomość została wysłana!</h3>
                <p className='text-[1.125rem] text-center'>Niebawem nasz konsultant skontaktuje się z Tobą i umówi spotkanie dotyczące Adaptivo!</p>
                <img src={Success} />
            </div>
            
            </>

            }
        </div>
    );
}

export default Form;