
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Backdrop } from '@mui/material';
import React, { forwardRef, Ref, RefObject, useEffect,FC,ReactNode, useState } from 'react';
import { Button } from '../UI/Button';
import close from '../../static/close.svg';
import marketingPhones from '../../static/marketingPhones.png';
import marketingWomen from '../../static/marketingWomen.png';
import marketingPhone from '../../static/marketingPhone.png';
import {createRef } from 'react';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { motion } from "framer-motion"
import Tile from './Tile';

const style = {
  position: 'absolute' as 'absolute',
  top: '10%',
  left: '0%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  height: "90%",
  bgcolor: '#010101',
  color: "#fff",
  border: '2px solid #000',
  boxShadow: 24,
  p: "0.5rem 2.5rem",
  borderRadius: "2rem 2rem 0  0",
  overflowY:'scroll',
  '@media (min-width: 1920px)': {
    width: '80%',
    left: '10%', 
  },
};

export default function MarketingModal(props: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const firstRef =  createRef<HTMLDivElement>();
  const secondRef =  createRef<HTMLDivElement>();
  const thirdRef =  createRef<HTMLDivElement>();

  const [refs,setRefs] = useState([firstRef, secondRef, thirdRef]);

  const scrollToComponent = (number: number) => {
      
  let componentRef = refs[number]; 
  console.log(componentRef)
    if (componentRef && 'current' in componentRef && componentRef.current) {
      console.log("XD")
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(()=>{
    scrollToComponent(props.number)
    console.log(refs)
  },[refs])

  return (

      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 1000,
            sx: {
              
              backgroundColor: 'rgba(53, 53, 53, 0.9)',
              backdropFilter: "blur(10px)",
              
            },
          },
        }}
        
      >
        <Slide in={props.open} direction="up">
        <Box sx={style} >
        
          <div className='flex flex-col gap-0 pb-10'>
          <div className='flex justify-end sticky top-0 right-0 -mr-2'>
            <img src={close} className='sticky top-5 -right-2 hover:cursor-pointer bg-black rounded-full -mr-8 p-2' onClick={()=>props.handleClose()}/>
            </div>
            <div className='flex flex-col gap-16' ref={firstRef}>
            <div className='flex flex-col gap-2'>
                <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold '>Wykorzystaj siłę <br /> automatyzacji marketingu!</h2>
            </div>
            <div className='flex flex-col gap-52'>
            <div className='flex flex-col lg:flex-row gap-16 lg:max-w-[90%]'>
            <Tile type={1} header={"Kilka kliknięć wystarczy, aby utworzyć stronę produktową!"} text={"Skorzystaj z kreatora szablonów, aby błyskawicznie utworzyć personalizowane strony internetowe.  "}
              buttonText={"Sprawdź jak wygląda generator"} reference={props.contact} onClick={()=>props.handleClose()}/>
              <div className='flex flex-col gap-4'>
                <h4 className='text-[1.75rem] font-bold'>Dopracuj prezentację produktową w kilka chwil</h4>
                <p className='text-[1.125rem] font-normal'>Nie musisz być ekspertem od tworzenia stron, by stworzyć atrakcyjną stronę produktową. Nasz kreator szablonów umożliwia korzystanie z gotowych, profesjonalnych szablonów, które zostały starannie zaprojektowane z myślą o różnorodnych potrzebach branżowych. Dodaj własne grafiki, zdjęcia produktów czy inne multimedia, aby jeszcze bardziej dostosować stronę do charakteru Twojego produktu oraz potrzeb Twojej marki.</p>
                <p className='text-[1.125rem] font-normal'>Adaptivo daje Ci kontrolę nad tym, co Twoi klienci mogą zobaczyć. Jeśli chcesz ukryć pewne treści i uczynić je dostępnymi wyłącznie dla zalogowanych użytkowników, nasz system umożliwia to w prosty sposób. Możesz na przykład zachęcić do logowania, oferując ekskluzywne materiały czy szczegółowe informacje dostępne jedynie dla wyselekcjonowanej grupy. Wszystko po to, aby dostarczać wartość na każdym etapie interakcji z klientem.</p>
                <img src={marketingPhones} className=''/>
              </div>
            </div>
            <div className='flex flex-col lg:flex-row gap-16 lg:ml-[10%]' ref={secondRef}>
              <div className='flex flex-col gap-4 lg:max-w-[70%]'>
                <h4 className='text-[1.75rem] font-bold'>Maksymalizuj możliwości Twojej bazy marketingowej</h4>
                <p className='text-[1.125rem] font-normal'>W świecie cyfrowego marketingu, zdolność do zbierania i zarządzania zgodami marketingowymi jest kluczem do budowy silnych relacji z klientami. Adaptivo umożliwia skuteczne zbieranie zgód, dając Ci pewność, że Twoje działania marketingowe są w pełni zgodne z przepisami. Co więcej, możesz w czasie rzeczywistym monitorować ilość zalogowanych użytkowników, obserwując, jak rośnie zaangażowanie w Twoje produkty.</p>
                <p className='text-[1.125rem] font-normal'>Nie czekaj na miesięczne raporty. Śledź na bieżąco, ile osób decyduje się na rejestrację, by korzystać z dodatkowych treści i ofert. Dzięki temu możesz błyskawicznie reagować na trendy, optymalizować swoje działania i dostosowywać strategie marketingowe w oparciu o rzeczywiste dane. Z Adaptivo, Twoja baza marketingowa staje się narzędziem pełnym możliwości.</p>
                <img src={marketingWomen} className='max-w-[80%] rounded-[32px]'/>
              </div>
              <Tile type={1} header={"Rozbuduj własną bazę marketingową"} text={"Zachęć użytkowników do sprawdzenia dodatkowych informacji o produkcie. Masz kontrolę nad tym, jakie informacje są widoczne dla wszystkich użytkowników, a jakie tylko dla tych zarejestrowanych. "}
              buttonText={"Jakie możliwości daje własna baza?"} reference={props.contact} onClick={()=>props.handleClose()}/>
            </div>
            <div className='flex flex-col lg:flex-row gap-16 lg:max-w-[90%]' ref={thirdRef}>
            <Tile type={1} header={"Udostępniaj informacje o promocjach!"} text={"Wykorzystaj przestrzeń marketingową do tego, aby poinformować swoich klientów o nowych promocjach! "}
              buttonText={"Przestrzeń marketingowa na stronie!"} reference={props.contact} onClick={()=>props.handleClose()}/>
              <div className='flex flex-col gap-4'>
                <h4 className='text-[1.75rem] font-bold'>Udostępniaj informacje o promocjach z własną grafiką</h4>
                <p className='text-[1.125rem] font-normal'>Niech twoje promocje wyróżniają się i przyciągają uwagę. Dzięki funkcji dodawania własnej grafiki promocyjnej, twoje oferty specjalne czy wyprzedaże nabiorą indywidualnego charakteru. Przyciągnij uwagę klientów i podkreśl wyjątkowość oferty!</p>
                <p className='text-[1.125rem] font-normal'>Kiedy twoja grafika przyciąga uwagę klienta, możesz przekierować go bezpośrednio na stronę z promocją, zwiększając szanse na finalizację zakupu. Co więcej, różne produkty mogą być promowane za pomocą różnych grafik. Dzięki pełnej możliwości modyfikacji, możesz dostosować każdą promocję do konkretnej grupy produktów, gwarantując maksymalną skuteczność swoich działań marketingowych.</p>
                <img src={marketingPhone} className='max-w-[80%] rounded-[32px]'/>
              </div>

            </div>
            </div>

          </div>
        </div>
          
        </Box>
        </Slide>
      </Modal>
  );
}