
import React, { forwardRef, Ref, RefObject, useEffect,FC,ReactNode } from 'react';
import Arrow from '../../static/Arrow.svg'

export interface ButtonProps {
 
    view?: string;
    text: string;
    reference?: RefObject<HTMLDivElement>;
    onClick? : () => void;
  }
  
export const Button: FC<ButtonProps> = ({text,onClick,reference}) => {
  
    const scrollToComponent = (componentRef: RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null) => {
        onClick && onClick()
        if (componentRef && 'current' in componentRef && componentRef.current) {
          
          componentRef.current.scrollIntoView({ behavior: 'smooth' });
          
        } else if (typeof componentRef === 'function') {
        
          componentRef(null);
        }
      };
    return(
        <button className="flex items-center gap-3 justify-center bg-secondary-900 text-black rounded-full px-2 py-1 text-left w-fit font-OpenSans text-[1rem]" onClick={() => reference ? scrollToComponent(reference) : onClick ? onClick() : null}>
            <b>{text}</b>
            <img src={Arrow} alt="arrow" className='bg-gray-900 rounded-full p-2'/>
        </button>
    )
}
