import React, { forwardRef, useState } from 'react';
import { Button } from '../UI/Button';
import learnMore from '../../static/learnMore.png';
import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';



const LearnMore = forwardRef((props: any, reference: any) => {

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

    return (
      <div ref={ref}>
      <Fade bottom duration={1500}  when={inView} >
      <div className="flex flex-col gap-8 justify-center items-center font-Lato px-4 lg:px-10 py-28 text-center " >
        <div className='flex flex-col gap-2'>
            <h2 className='text-[2.5rem] lg:text-[3.75rem] font-bold '>Chesz wiedzieć więcej?</h2>
            <p className='text-[1.125rem]'>Umów się na rozmowę z ekspertami i dowiedz się, jak na podstawie danych zdobyć znaczną przewagę konkurencyjną!</p>
        </div>

        <img src={learnMore} />
        <Button text={"Umów się na rozmowę"} reference={props.contact}/>
      </div>
      </Fade>
        

      </div>
    );
  });
  
  export default LearnMore;