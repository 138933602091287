import React, { forwardRef } from 'react';
import { Button } from '../UI/Button';
import step1 from '../../static/step1.png';
import step2 from '../../static/step2.png';
import step3 from '../../static/step3.png';

import { useInView } from 'react-intersection-observer';
import {Fade} from 'react-reveal';


const Step = forwardRef((props: any, reference: any) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
   

    const images = [step1,step2,step3]
    
    return (
      <div ref={ref}>
      <Fade left duration={2000}  when={inView}>
      <div className="flex flex-col xl:flex-row gap-12 p-4 lg:p-8 font-Lato bg-gray-900 rounded-[2rem] min-h-[22.5rem] xl:max-w-[1100px]" >
        <div className='flex flex-col gap-2 xl:max-w-[45%]'>
            <h4 className='text-[0.875rem] font-bold'>Krok {props.number+1}</h4>
            <h3 className='text-[1.75rem] lg:text-[2rem] font-semibold'> {props.header}</h3>
            <p className='text-[1.125rem]'>
                {props.text}
            </p>
        </div>
        <img src={images[props.number]} className='w-full xl:max-w-[500px] rounded-[32px]'/>
      </div>
      </Fade>
      </div>
    );
  });
  
  export default Step;